import "./App.css";
import Hero from "./firstpage/hero";
import Second from "./secondpage/2nd";
import Third from "./thirdpage/3rd";

function App() {
  return (
    <>
      <Hero />
      <Second />
      <Third />
    </>
  );
}

export default App;
